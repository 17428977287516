<template>
  <div class="categorie">
    <div class="page-header">
      <h1>{{ $t('Service Management') }} > {{ $t('Categories') }} > {{ $t('Add') }}</h1>
    </div>
    <div class="page-content">
      <CategorieForm @action="addCategorie" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CategorieForm from '@/components/service/CategorieForm.vue';
import Common from '@/lib/common';
import Service from '@/lib/service';

export default {
  name: 'AddCategorie',
  components: {
    CategorieForm,
  },
  methods: {
    async addCategorie(form) {
      try {
        const loginInfo = Common.getLoginInfo();
        const categorie = await Service.createCategorie(this.apiUrl, form, loginInfo);
        this.$router.push('/service/categories');
      } catch (err) {
        console.log(err);
      }
    },
    async getCategoriesByName(categorieNames) {
      const categories = [];
      const loginInfo = Common.getLoginInfo();
      try {
        for (let i = 0; i < categorieNames.length; i++) {
          const categorieName = categorieNames[i];
          const searchFields = {};
          if (this.langcode === 'zh') {
            searchFields['categorie_name_zh'] = categorieName;
          } else {
            searchFields['categorie_name_en'] = categorieName;
          }
          const categorie = await Product.searchCategorie(this.apiUrl, searchFields, loginInfo);
          const categorieId = categorie[0].id;
          categories.push(categorieId);
        }
      } catch (err) {
        console.log(err);
      }
      return categories;
    },
  },
  data() {
    const form = {
      categorieNameZh: '',
      categorieNameEn: '',
    };
    return {
      form,
    }
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.content-header {
  display: flex;

  h2 {
    flex: 1;
  }

  .action-button {
    margin-right: 20px;
  }
}

.submit-wrapper {
  text-align: right;
}
</style>