<template>
    <div class="accounts-setting">
      <el-dialog :title="$t('User information')" :visible.sync="displayUserForm" width="60%">
        <div class="form-content">
          <el-form ref="form" :model="form">
            <el-form-item :label="$t('Username')" style="max-width: 80%">
              <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Password')" style="max-width: 80%">
              <el-input type="password" v-model="form.password" @focus="activePasswordField"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Email Address')" style="max-width: 80%">
              <el-input v-model="form.email_address"></el-input>
            </el-form-item>
          </el-form>
          <div class="submit-wrapper">
            <el-button class="submit-button" type="primary" v-if="action === 'edit'" @click="updateUser">{{ $t('Submit') }}</el-button>
            <el-button class="submit-button" type="primary" v-if="action === 'create'" @click="createUser">{{ $t('Submit') }}</el-button>
          </div>
        </div>
      </el-dialog>
  
      <div class="page-header">
        <h1>{{ $t('Settings')}} > {{ $t('Account Settings') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Account Settings') }}</h2>
        </div>
        <div class="main-account">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>{{ $t('Main Account (For Manager)') }}</span>
            </div>
            <div class="account">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t('Username') }}</th>
                    <th scope="col">{{ $t('Email Address') }}</th>
                    <th scope="col">{{ $t('Actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="users[0]">
                    <td>{{ users[0].username }}</td>
                    <td>{{ users[0].email_address }}</td>
                    <td>
                      <div class="account-acions">
                        <a class="action-link" href="#" @click.prevent="editUser(users[0])">{{ $t('Edit') }}</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-card>
  
          <div class="admin-accounts">
            <el-card class="box-card">
              <div class="box-card-header" slot="header">
                <span class="card-title">{{ $t('Staff Accounts') }}</span>
                <el-button type="primary" @click="addUser">{{ $t('Add') }}</el-button>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t('Username') }}</th>
                    <th scope="col">{{ $t('Email Address') }}</th>
                    <th scope="col">{{ $t('Actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in users" v-if="index !== 0">
                    <td>{{ user.username }}</td>
                    <td>{{ user.email_address }}</td>
                    <td>
                      <div class="account-acions">
                        <a class="action-link" @click.prevent="editUser(user)" href="#">{{ $t('Edit') }}</a>
                        <a class="action-link danger" href="#" @click.prevent="deleteUser(user.id)">{{ $t('Delete')}}</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import Common from '@/lib/common';
  import User from '@/lib/user';
  import UserForm from '@/components/setting/UserForm.vue';
  
  export default {
    name: 'AccountSettings',
    components: {
      UserForm,
    },
    data(){
      const form = {
        username: '',
        password: 'placeholder',
        email_address: '',
      };
      return {
        form,
        users: [],
        currentUserId: null,
        action: '',
        displayUserForm: false,
      };
    },
    mounted(){
      this.loadUsers();
    },
    methods: {
      activePasswordField(){
        //Only clean password field when it is placeholder
        if(this.form.password === 'placeholder'){
          this.form.password = '';
        }
      },
      addUser(){
        this.action = 'create';
        this.displayUserForm = true;
        this.currentUserId = null;
        this.form.username = '';
        this.form.password = '';
        this.form.email_address = '';
      },
      editUser(user){
        this.action = 'edit';
        this.displayUserForm = true;
        this.currentUserId = user.id;
        this.form.username = user.username;
        this.form.email_address = user.email_address;
      },
      async createUser(){
        try{
          if(this.form.username.length === 0 || this.form.email_address.length === 0 || this.form.password.length === 0 ){
            this.$message.error(this.$t('Please input all of the field.'));
            return;
          }
          const loginInfo = Common.getLoginInfo();
          const user = await User.createUser(this.apiUrl, this.form, loginInfo);
          location.reload();
        }catch(err){
          console.log(err);
        }
      },
      async updateUser(){
        try{
          const loginInfo = Common.getLoginInfo();
          const user = await User.updateUser(this.apiUrl, this.currentUserId, this.form, loginInfo);
          location.reload();
        }catch(err){
          console.log(err);
        }
      },
      async deleteUser(userId){
        try{
          const loginInfo = Common.getLoginInfo();
          const user = await User.deleteUser(this.apiUrl, userId, loginInfo);
          location.reload();
        }catch(err){
          console.log(err);
        }
      },
      async loadUsers(){
        try{
          const loginInfo = Common.getLoginInfo();
          const users = await User.loadAllUsers(this.apiUrl, loginInfo);
          this.users = users;
        }catch(err){
          console.log(err);
        }
      },
    },
    computed: {
      ...mapState({
       apiUrl: state => state.apiUrl,
     })
    },
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  .main-account{
    width: 98%;
    margin-left: 20px;
  }
  
  .account{
    display: flex;
    .account-name{
      margin-right: 50px;
    }
    .account-email{
      flex: 1;
    }
  }
  
  .account-acions{
    .action-link{
      margin-right: 10px;
    }
    .danger{
      color: red;
    }
  }
  .box-card-header{
    display: flex;
    .card-title{
      flex: 1;
    }
  }
  .admin-accounts{
    margin-top: 50px;
    margin-bottom: 20px;
  }
  </style>