const state = {
  currentService: null,
  currentCategorie: null,
};

const mutations = {
  updateService(nextState, service){
    nextState.currentService = service;
  },
  updateCategorie(nextState, categorie){
    nextState.currentCategorie = categorie;
  },
};

const actions =  {
  setCurrentService: (context, service) => context.commit('updateService', service),
  removeCurrentService: (context) => context.commit('updateService', null),
  setCurrentCategorie: (context, categorie) => context.commit('updateCategorie', categorie),
  removeCurrentCategorie: (context) => context.commit('updateCategorie', null),
};

export default {
  state,
  actions,
  mutations,
};
