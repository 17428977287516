<template>
  <div class="service">
    <div class="page-header">
      <h1>{{ $t('Service Management') }} > {{ $t('Service') }} > {{ $t('Add') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Add Service') }}</h2>
      </div>
      <ServiceForm @action="addService"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Service from '@/lib/service';
import ServiceForm from '@/components/service/ServiceForm.vue';

export default {
  name: 'CreateService',
  components: {
    ServiceForm
  },
  methods:{
    async addService(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const serviceInfo = Object.assign({}, form);
        const categories = await this.getCategoriesByName(form.categories);
        serviceInfo.categories = categories;
        const service = await Service.createService(this.apiUrl, serviceInfo, loginInfo);
        this.$router.push('/services');
      }catch(err){
        console.log(err);
      }
    },
    async getCategoriesByName(categorieNames){
      const categories = [];
      const loginInfo = Common.getLoginInfo();
      try{
        for(let i = 0 ; i < categorieNames.length; i++){
          const categorieName = categorieNames[i];
          const searchFields = {};
          if(this.langcode === 'zh'){
            searchFields['categorie_name_zh'] = categorieName;
          }else{
            searchFields['categorie_name_en'] = categorieName;
          }
          const categorie = await Service.searchCategorie(this.apiUrl, searchFields, loginInfo);
          const categorieId = categorie[0].id;
          categories.push(categorieId);
        }
      }catch(err){
        console.log(err);
      }
      return categories;
    },
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
