<template>
    <div class="form-content">
      <el-form ref="form" :model="form">
        <el-form-item :label="$t('Username')" style="max-width: 80%">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Password')" style="max-width: 80%">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Email Address')" style="max-width: 80%">
          <el-input v-model="form.email_address"></el-input>
        </el-form-item>
      </el-form>
      <div class="submit-wrapper">
        <el-button class="submit-button" type="primary" @click="submit">{{ $t('Submit') }}</el-button>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  
  export default {
    name: 'UserForm',
    methods:{
      submit(){
        this.$emit('action', this.form);
      },
    },
    data(){
      const form = {
        username: '',
        password: '',
        email_address: '',
      };
      return {
        form,
      }
    },
    computed: mapState({
      apiUrl: state => state.apiUrl,
    }),
  }
  </script>
  
  <style lang="scss">
  .form-content{
    padding-left: 20px;
    .el-form-item__label{
      float: none;
    }
    .update-button{
      .el-form-item__content{
        text-align: right;
      }
    }
    .submit-wrapper{
      text-align: right;
    }
  }
  </style>