<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button class="btn btn-primary saveButton" @click="save">{{ $t('Save') }}</button>
    </div>
    <div class="service-infomration">
      <el-form class="service-form" ref="service-form" :model="form" label-width="150px" label-position="left">
        <div class="create-service-form">
          <div class="row">
            <el-form-item :label="$t('Service Name (Chinese)')" style="max-width: 100%">
              <el-input v-model="form.serviceNameZh"></el-input>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item :label="$t('Service Name (English)')" style="max-width: 100%">
              <el-input v-model="form.serviceNameEn"></el-input>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item :label="$t('Categories')" style="max-width: 100%">
              <el-checkbox-group v-model="form.categories">
                <el-checkbox v-if="langcode == 'zh'" :key="categorie.id" v-for="categorie in categories"
                  :label="categorie.categorie_name_zh"></el-checkbox>
                <el-checkbox v-if="langcode == 'en'" :key="categorie.id" v-for="categorie in categories"
                  :label="categorie.categorie_name_en"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item :label="$t('Service Price') + ' (HK$)'" style="max-width: 100%">
              <el-input-number v-model="form.servicePrice" :precision="1" :step="0.1"></el-input-number>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Service from '@/lib/service';

export default {
  name: 'ServiceForm',
  data() {
    //Default form value
    let form = {
      serviceNameZh: '',
      serviceNameEn: '',
      servicePrice: '',
      categories: [],
    };

    return {
      form,
      categories: [],
    };
  },
  mounted(){
    this.loadCategories();
  },
  methods: {
    getCategoriesById(categorieIds){
      const categories = this.categories.filter(function(categorie){
        if(categorieIds.includes(categorie.id)){
          return true;
        }
      });
      const categorieNames = [];
      for(let i = 0; i < categories.length; i++){
        if(this.langcode === 'zh'){
          categorieNames.push(categories[i].categorie_name_zh);
        }else{
          categorieNames.push(categories[i].categorie_name_en);
        }
      }
      return categorieNames;
    },
    async loadCategories(){
      const loginInfo = Common.getLoginInfo();
      try{
        const categories = await Service.loadAllCategories(this.apiUrl, loginInfo);
        this.categories = categories;
      }catch(err){
        console.log(err);
      }
    },
    save() {
      this.$emit('action', this.form);
    },
  },
  watch: {
    'currentService': function () {
      const form = {
        serviceNameZh: this.currentService.service_name_zh,
        serviceNameEn: this.currentService.service_name_en,
        servicePrice: this.currentService.service_price,
      };
      //Load Categories Name
      form.categories = this.getCategoriesById(this.currentService.service_categories);
      this.form = form;
    },
  },
  computed: mapState({
    currentService: state => state.service.currentService,
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.service {
  .action-button-wrapper {
    text-align: right;
    margin-bottom: 50px;
  }

  .form-wrapper {
    padding-left: 20px;
  }

  .service-form {
    padding-left: 20px;
  }

  .el-card__header {
    background-color: black;
    color: white;
  }

  .el-form-item.email {
    margin-right: 30px;
  }

  .service-orders-card {
    margin-top: 50px;
  }
}
</style>
